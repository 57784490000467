import { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { useAlert } from 'react-alert'
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CardActions from '@material-ui/core/CardActions';
import FormControl from '@material-ui/core/FormControl';
import { request } from '../helpers/request';
import Loader from '../components/Loader';
import moment from 'moment';
import 'moment/locale/es';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEuroSign } from '@fortawesome/free-solid-svg-icons'
import Cookies from 'universal-cookie';
const cookies = new Cookies();
moment.locale('es');

const useStyles = makeStyles({
    root: {
        minWidth: 275,
        marginTop: 20
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
});


const Payment = () => {
    const alert = useAlert()
    const [searched, setSearched] = useState(false);
    const [loading, setLoading] = useState(false);
    const [clientId, setClientId] = useState();
    const [invoices, setInvoices] = useState([]);
    const classes = useStyles();

    const getAllInvoices = async () => {
        setLoading(true);
        setSearched(true);
        const url = `/invoices/client/${clientId}`;
        try {
            const { data } = await request.get(url);
            setInvoices(data);
            setLoading(false);
        } catch (e) {
            setLoading(false);
        }
    };

    const startPayment = async (invoice) => {
        try {
            setLoading(true);
            const { data: sessionId } = await request.put(`/invoices/init/${invoice.id}`);
            cookies.set('payment_in_progress', invoice.id);
            console.log(invoice.id, cookies.get('payment_in_progress'))
            setLoading(false);
            
            
            
            const { data: stripeKey } = await request.get(`/users/stripe-key`);
            const stripePromise = loadStripe(stripeKey);
            const stripe = await stripePromise;
            const result = await stripe.redirectToCheckout({
                sessionId
            });
            console.log(invoice.id, cookies.get('payment_in_progress'))
            if (result.error) {
                // If `redirectToCheckout` fails due to a browser or network
                // error, display the localized error message to your customer
                // using `result.error.message`.
                cookies.set('payment_in_progress', null);
                alert.error('Hubo un error ejecutando el pago');
            }
        } catch (e) {
            console.log(e)
            setLoading(false);
            cookies.set('payment_in_progress', null);
            alert.error('Hubo un error ejecutando el pago');
        }
    }

    return (
        <div className="container">
            {loading && <Loader isLoading={loading} />}
            <img alt="rh-logo" style={{ margin: '0px auto', display: 'block' }} variant="top" src="https://roashunter.com/wp-content/uploads/2019/12/LOGO_HEAD_PRUEBA-02.png" />
            <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                <FormControl className={classes.formControl}>
                    <TextField value={clientId} onChange={e => setClientId(e.target.value)} label="Nro de cliente" type="text" placeholder="Ingresa tu numero de cliente" />
                </FormControl>
                <FormControl style={{ marginLeft: 10 }} className={classes.formControl}>
                    <Button variant="contained" color="primary" onClick={getAllInvoices}>Buscar</Button>
                </FormControl>
            </div>
            {!loading && searched && !invoices.length && <p style={{ textAlign: 'center' }}>No hay pagos pendientes</p>}
            {invoices.length > 0 &&
                <>
                    {
                        invoices.map(invoice => (
                            <Card className={classes.root}>
                                <CardContent>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                                        {moment(invoice.date * 1000).format('DD/MM/YYYY')}
                                    </Typography>
                                    <Typography variant="h3" component="h2">
                                        €{parseFloat(invoice.total).toFixed(2)}
                                    </Typography>
                                    {
                                        invoice.products.map(product => <Typography variant="body2" component="p">{product.name}</Typography>)
                                    }
                                </CardContent>
                                <CardActions style={{ justifyContent: 'flex-end' }}>
                                    <span>*Una vez pagada la primera factura, el pago recurrente será activado para el método de pago que hayas usado</span>
                                    <Button onClick={() => startPayment(invoice)} variant="contained" color="primary"><FontAwesomeIcon icon={faEuroSign} />&nbsp;&nbsp;Pagar</Button>
                                </CardActions>
                            </Card>
                        ))
                    }
                </>
            }
        </div>
    );
}

export default Payment;