import React from "react";
import LinearProgress from '@material-ui/core/LinearProgress';


const Loader = ({ isLoading = false }) => {
  
  return (
    <div style={{display: isLoading ? 'block' : 'none', position: 'fixed', top: 0, left:0, width: '100%', height: '100%', textAlign: 'center', backgroundColor: 'rgba(255,255,255,0.8)', zIndex: 1000000}}>
      <LinearProgress />
    </div>
  );
}

export default Loader;
