import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { loadStripe } from '@stripe/stripe-js';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Chip from '@material-ui/core/Chip';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { request } from '../helpers/request';
import { useAlert } from 'react-alert'
import Loader from '../components/Loader';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import moment from 'moment';
import { styled } from '@material-ui/core/styles';
import 'moment/locale/es';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStopCircle, faEuroSign } from '@fortawesome/free-solid-svg-icons'
import Cookies from 'universal-cookie';
import { TextField } from '@material-ui/core';
const cookies = new Cookies();
moment.locale('es');

const MyButton = styled(Button)({
    background: 'linear-gradient(45deg, #3292a4 30%, #7a3286 90%)',
    border: 0,
    borderRadius: 3,
    color: 'white',
    height: 48,
    padding: '0 30px',
});

const MyInvertedButton = styled(Button)({
    background: 'linear-gradient(45deg, #7a3286 30%, #3292a4 90%)',
    border: 0,
    borderRadius: 3,
    color: 'white',
    height: 48,
    padding: '0 30px',
});

const STRIPE_PAYMENTS = {
    stripe_card: 'Tarjeta de crédito',
    stripe_iban: 'IBAN',
}

const PM_TYPE = {
    card: 'Tarjeta de crédito',
    sepa_debit: 'IBAN'
}

const INVOICE_STATUS = {
    'pending': <Chip variant="outlined" color="primary" label="Pendiente de pago" />,
    'paused': <Chip variant="outlined" color="warning" label="Pausada" />,
    'confirming': <Chip variant="outlined" color="warning" label="En proceso de pago" />,
    'expired': <Chip variant="outlined" color="secondary" label="Vencida" />,
    'paid': <Chip variant="outlined" color="success" label="Pagada" />
}

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));


const Invoices = () => {
    const classes = useStyles();
    const alert = useAlert();
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [status, setStatus] = useState(0);
    const [paymentMethod, setPaymentMethod] = useState();
    const [invoices, setInvoices] = useState([]);
    const [invoice, setInvoice] = useState({});
    const [selectedClient, setSelectedClient] = useState({
        client: {}
    });
    const [stripeInformation, setStripeInformation] = useState({
        paymentMethodsCard: [],
        paymentMethodsSEPA: []
    });

    const checkInvoiceNumber = (text) => {
        setDisabled(!(text === invoice.docNumber));
    }

    const getAllInvoices = async () => {
        setLoading(true);
        let url = `/invoices`;
        if (status !== 'all') {
            url += `?status=${status}`;
        }
        const { data } = await request.get(url);
        setInvoices(data);
        setLoading(false);
    };


    const pauseInvoice = async invoiceId => {
        setLoading(true);
        const url = `/invoices/pause/${invoiceId}`;
        await request.put(url);
        getAllInvoices();
    }

    const startPayment = async (invoice) => {
        try {
            setLoading(true);
            const { data: sessionId } = await request.put(`/invoices/init/${invoice.id}`);
            cookies.set('payment_in_progress', invoice.id);
            console.log(invoice.id, cookies.get('payment_in_progress'))
            setLoading(false);



            const { data: stripeKey } = await request.get(`/users/stripe-key`);
            const stripePromise = loadStripe(stripeKey);
            const stripe = await stripePromise;
            const result = await stripe.redirectToCheckout({
                sessionId
            });
            console.log(invoice.id, cookies.get('payment_in_progress'))
            if (result.error) {
                // If `redirectToCheckout` fails due to a browser or network
                // error, display the localized error message to your customer
                // using `result.error.message`.
                cookies.set('payment_in_progress', null);
                alert.error('Hubo un error ejecutando el pago');
            }
        } catch (e) {
            console.log(e)
            setLoading(false);
            cookies.set('payment_in_progress', null);
            alert.error('Hubo un error ejecutando el pago');
        }
    }

    const onClickInvoice = async invoice => {
        if (invoice.dynamoData.status !== 'pending' && invoice.dynamoData.status !== 'confirming') return;
        setDisabled(true);
        setPaymentMethod('');
        const customer = invoice.customer;
        setInvoice(invoice);
        setLoading(true);
        const { data } = await request.get(`/clients/${customer.id}`);
        setLoading(false);
        setSelectedClient(customer);
        setStripeInformation(data.stripeCustomer);
        setShow(true);
    }

    const chargeInvoice = async () => {
        try {
            setLoading(true);
            const url = `/invoices/charge/${invoice.id}`;
            await request.put(url, {
                paymentMethod
            });
            await getAllInvoices();
            setShow(false);
            alert.success('Cobro hecho correctamente');
        } catch (e) {
            setLoading(false);
            setShow(false);
            alert.error('Hubo un error ejecutando el pago');
        }
    }

    const resetInvoice = async () => {
        try {
            setLoading(true);
            const url = `/invoices/reset/${invoice.id}`;
            await request.put(url, {});
            await getAllInvoices();
            setShow(false);
            alert.success('Factura reiniciada correctamente');
        } catch (e) {
            setLoading(false);
            setShow(false);
            alert.error(e.response.data.error.message);
        }
    }

    useEffect(() => {
        getAllInvoices();
        // eslint-disable-next-line
    }, [status])

    return (
        <div>
            {loading && <Loader isLoading={loading} />}

            {/* <FormControl className={classes.formControl}>
                <InputLabel id="clientFilterLabel">Cliente</InputLabel>
                <Select id="selectClientlabel" labelId="clientFilterLabel" value={selectedClient} onChange={({ target: { value } }) => setSelectedClient(value)} as="select">
                    <MenuItem value="all">Todos</MenuItem>
                    {
                        clients.map(client => <MenuItem key={client.clientId} value={client.clientId}>{client.company_name}</MenuItem>)
                    }
                </Select>
            </FormControl> */}
            <FormControl className={classes.formControl}>
                <InputLabel id="stateLabel">Estado</InputLabel>
                <Select labelId="stateLabel" id="stateFilter" value={status} onChange={({ target: { value } }) => setStatus(value)} as="select">
                    <MenuItem value="all">Todos</MenuItem>
                    <MenuItem value={1}>Pagada</MenuItem>
                    <MenuItem value={0}>Pendientes</MenuItem>
                </Select>
            </FormControl>
            {/* <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
            }}>
                <FormControl className={classes.formControl}>
                    <div>{state[0].startDate.toLocaleDateString()} - {state[0].endDate.toLocaleDateString()}</div>
                </FormControl>
                <FormControl className={classes.formControl}>
                    <Button variant="contained" color="primary" onClick={() => setShow(true)}>Filtrado por fecha {dateToFilter === 'created' ? 'de creación' : (dateToFilter === 'due' ? 'de vencimiento' : '')}</Button>
                </FormControl>
            </div>
            {hasMore &&
                <FormControl className={classes.formControl}>
                    <Button variant="contained" color="success" onClick={loadNewInvoices}>Cargar facturas</Button>
                </FormControl>
            } */}
            <div style={{ marginTop: 20, marginBottom: 20 }}>
                <span><FontAwesomeIcon color={'red'} icon={faStopCircle} /> Detener cobro automatico</span>
                {/* <span style={{ marginLeft: 20 }}><FontAwesomeIcon icon={faEuroSign} /> Cobrar ya</span> */}
            </div>
            {!loading &&
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>#</TableCell>
                                <TableCell>CLIENTE</TableCell>
                                <TableCell>ESTADO</TableCell>
                                <TableCell>DESCRIPCIÓN</TableCell>
                                <TableCell>MONTO</TableCell>
                                <TableCell>VENCIMIENTO</TableCell>
                                <TableCell>CREADO</TableCell>
                                <TableCell>TIPO DE PAGO</TableCell>
                                <TableCell>ACCIONES</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!invoices.length && <span style={{ textAlign: 'center' }}>No hay registros</span>}
                            {
                                invoices.map(invoice => (
                                    <TableRow onClick={() => onClickInvoice(invoice)} className="table-hover-tr" key={invoice.id}>
                                        <TableCell>{invoice.docNumber}</TableCell>
                                        <TableCell>{invoice.contactName}</TableCell>
                                        <TableCell>{INVOICE_STATUS[(invoice.dynamoData || {}).status] || invoice.status}</TableCell>
                                        <TableCell>{invoice.products[0].name}</TableCell>
                                        <TableCell>€{parseFloat(invoice.total).toFixed(2)}</TableCell>
                                        <TableCell>{moment.unix((invoice.dynamoData || new Date()).charge_at).calendar()}</TableCell>
                                        <TableCell>{moment.unix(invoice.date).calendar()}</TableCell>
                                        <TableCell>{invoice.tags.filter(tag => tag === "stripe_iban" || tag === "stripe_card").map(tag => <Chip variant="info" label={STRIPE_PAYMENTS[tag]} />)}</TableCell>
                                        <TableCell>
                                            {(invoice.dynamoData || {}).status === 'pending' && <Button onClick={() => pauseInvoice(invoice.id)}><FontAwesomeIcon color={'red'} icon={faStopCircle} /></Button>}
                                            {/* <Button onClick={() => chargeInvoice(invoice)}><FontAwesomeIcon icon={faEuroSign} /></Button> */}
                                        </TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            }


            <Modal show={show} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Factura {invoice.docNumber} de {selectedClient.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Para usar nuevos metodos de pago el usuario debe añadirlos haciendo un pago manual, a partir de ahí quedarán disponibles para cobro automático</p>

                    <p><b>Número de cliente para pagos:&nbsp;&nbsp;</b>
                        <CopyToClipboard text={selectedClient.id || 'Sin asignar'}
                            onCopy={() => alert.info("Copiado")}>
                            <span className="copy-clipboard-client-name">{(selectedClient || {}).id || 'Sin asignar'}</span>
                        </CopyToClipboard>
                    </p>

                    {
                        (stripeInformation.paymentMethodsCard || []).length === 0 && (stripeInformation.paymentMethodsSEPA || []).length === 0 && <p><b>El cliente aún no tiene configurados ningún método de pago</b></p>
                    }

                    {(invoice.dynamoData || {}).status === 'pending' && stripeInformation.paymentMethodsCard.concat(stripeInformation.paymentMethodsSEPA).length > 0 && <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="paymentMethod">Método de pago</InputLabel>
                        <Select
                            native
                            style={{width: '15em'}}
                            value={paymentMethod}
                            onChange={(e) => setPaymentMethod(e.target.value)}
                            inputProps={{
                                name: 'paymentMethod',
                                id: 'paymentMethod',
                            }}
                        >
                            <option aria-label="None" value="" />
                            {
                                stripeInformation.paymentMethodsCard.concat(stripeInformation.paymentMethodsSEPA).map(pm => {
                                    return <option value={pm.id}>{PM_TYPE[pm.type]} •••• {pm[pm.type].last4}</option>
                                })
                            }
                        </Select>
                        <br/>
                    </FormControl>}
                    <br/>
                    <TextField style={{width: '15em'}} onChange={e => checkInvoiceNumber(e.target.value)} id="standard-basic" label="Ingresa el número de la factura" />
                </Modal.Body>
                {(invoice.dynamoData || {}).status === 'confirming' && <Modal.Footer>
                    <MyInvertedButton onClick={resetInvoice} disabled={disabled} variant="contained" color="primary" type="submit"><FontAwesomeIcon icon={faEuroSign} />&nbsp;&nbsp;Reset factura</MyInvertedButton>
                </Modal.Footer>
                }
                {selectedClient.stripeId && invoice.dynamoData.status === 'pending' && <Modal.Footer>
                    {!paymentMethod && <MyButton disabled={disabled} onClick={() => startPayment(invoice)} variant="contained"><FontAwesomeIcon icon={faEuroSign} />&nbsp;&nbsp;Pago manual</MyButton>}
                    {paymentMethod && <MyInvertedButton onClick={chargeInvoice} disabled={disabled} variant="contained" color="primary" type="submit"><FontAwesomeIcon icon={faEuroSign} />&nbsp;&nbsp;Pago directo</MyInvertedButton>}
                </Modal.Footer>
                }
            </Modal>

        </div>
    );
}

export default Invoices;
