import Login from "../views/Login.js";
import Clients from "../views/Clients.js";
import Invoices from "../views/Invoices.js";
import Payment from "../views/Payment.js";
import Success from "../views/Success.js";
import Cancel from "../views/Cancel.js";

var routes = [{
    path: "/login",
    name: "RoasHunter",
    noshow: true,
    icon: "fas fa-user",
    component: Login,
    layout: ""
},{
    path: "/invoices",
    name: "Facturas",
    noshow: false,
    icon: "fas fa-user",
    component: Invoices,
    layout: ""
 },{
    path: "/clients",
    name: "Clientes",
    noshow: false,
    icon: "fas fa-user",
    component: Clients,
    layout: ""
},{
    path: "/pago-de-facturas-roas-hunter",
    name: "Pago",
    noshow: true,
    icon: "fas fa-user",
    component: Payment,
    layout: ""
},{
    path: "/success",
    name: "Pago",
    noshow: true,
    icon: "fas fa-user",
    component: Success,
    layout: ""
},{
    path: "/cancel",
    name: "Pago",
    noshow: true,
    icon: "fas fa-user",
    component: Cancel,
    layout: ""
},
//,{
//     path: "/products",
//     name: "Productos",
//     noshow: false,
//     icon: "fas fa-user",
//     component: Products,
//     layout: ""
// },{
//     path: "/clients/:clientId([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})",
//     name: "Detalle Clientes",
//     noshow: true,
//     icon: "fas fa-user",
//     component: ClientDetail,
//     layout: ""
];
export default routes;