import React from "react";
import { Route, Link, Switch } from "react-router-dom";
import routes from "./routes.js";
import { Row, Col, Container } from 'react-bootstrap';

const sessionlessroutes = ["/login", '/pago-de-facturas-roas-hunter', '/cancel', '/success'];


class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: "black",
      activeColor: "info"
    };
    this.mainPanel = React.createRef();
  }

  onLinkClick = path => this.setState({path})

  render() {
    if (sessionlessroutes.indexOf(window.location.pathname) !== -1) 
      return (<Switch>
          {routes.map((prop, key) => {
            return (
              <Route
                path={prop.layout + prop.path}
                component={prop.component}
                key={key}
              />
            );
          })}
        </Switch>);

    if (!window.localStorage.getItem("auth_token")) return window.location.href = "/login";
    if (window.location.pathname === "/") return window.location.href = "/invoices";

    return (
      <Row style={{height: '100vh'}}>
        <Col className="main-sidebar" md={2}>
          <img alt="rh-logo" style={{margin: '0px auto',display: 'block'}} variant="top" src="https://roashunter.com/wp-content/uploads/2019/12/LOGO_HEAD_PRUEBA-02.png" />
          <br/>
          <div className="link-container">
          {routes.map((prop, key) => prop.noshow ? '' : <div key={key}><Link className={window.location.pathname === prop.path ? 'link-container-selected' : ''} key={key} onClick={() => this.onLinkClick(prop.path)} to={prop.path}>{prop.name}</Link></div>)}
          </div>
        </Col>
        <Col className="main-panel" md={10} ref={this.mainPanel}>
          <Container>
            <Switch>
              {routes.map((prop, key) => {
                return (
                  <Route
                    exact
                    path={prop.layout + prop.path}
                    component={prop.component}
                    key={key}
                  />
                );
              })}
            </Switch>
          </Container>
        </Col>
      </Row>
    );
  }
}

export default Main;
