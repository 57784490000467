import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import success from '../assets/success.png'
import { request } from '../helpers/request';
import { useEffect, useCallback } from 'react';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
const Cancel = ({ history }) => {

    const checkPayment = useCallback(async () => {
        try {
            const invoiceId = cookies.get('payment_in_progress');
            if (invoiceId) {
                await request.put(`/invoices/check/${invoiceId}`);
            }
            cookies.set('payment_in_progress', null);
        } catch (e) {
            console.log(e);
            cookies.set('payment_in_progress', null);
        }
    }, [])

    useEffect(() => {
        checkPayment();
    }, [checkPayment])


    return (
        <div className="container text-center">
            <img alt="rh-logo" style={{ margin: '0px auto', display: 'block' }} variant="top" src="https://roashunter.com/wp-content/uploads/2019/12/LOGO_HEAD_PRUEBA-02.png" />
            <img alt="success" style={{marginTop: 50}} src={success} />
            <Typography variant="h4" component="h3">Gracias por tu pago</Typography>
            <br/>
            <br/>
            <Button onClick={() => history.replace('/pago-de-facturas-roas-hunter')} variant="contained" color="primary"><FontAwesomeIcon icon={faArrowLeft} /> &nbsp;&nbsp;Pagar más facturas</Button>
        </div>
    );
}

export default Cancel;