import { useState } from 'react';
import { Card, Button, Form } from 'react-bootstrap';
import { request } from '../helpers/request';
import Loader from '../components/Loader';
import { useAlert } from 'react-alert'

const Login = () => {
  const alert = useAlert();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  
  const login = async () => {
    try {
      setLoading(true);
      const response =  await request.post('/users/login', {
        email,
        password
      });
      setLoading(false);
      window.localStorage.setItem('auth_token', response.data.token);
      window.location.href = "/dashboard";
    } catch(e) {
      console.log(e)
      setLoading(false);
      alert.error("Usuario o contraseña inválida")
    }
  }

  return (
    <div className="App">
      {loading && <Loader isLoading={loading} />}
      <Card style={{ width: '18rem', margin: '9em auto' }}>
        <Card.Img style={{width: '8em', margin: '0 auto'}} variant="top" src="https://roashunter.com/wp-content/uploads/2019/12/LOGO_HEAD_PRUEBA-02.png" />
        <Card.Body>
          <Form>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control onChange={event => setEmail(event.target.value)} type="email" placeholder="Enter email" />
            </Form.Group>

            <Form.Group controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control onChange={event => setPassword(event.target.value)} type="password" placeholder="Password" />
            </Form.Group>
            <Button onClick={login} variant="primary">
              Login
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
}

export default Login;
