const axios = require('axios');
const isLocal = window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1";

const BASE_URL = isLocal ? "http://localhost:3000" : "https://api.roashunter.com";

const request = axios.create({
    baseURL: BASE_URL + "/api/",
    timeout: 90000,
    headers: {
        'Authorization': `Bearer ${window.localStorage.getItem('auth_token')}`
    }
});

request.interceptors.response.use(function(response) {
    return response;
}, function(error) {
    if ((error.response || {}).status === 401) {
        window.localStorage.clear();
        window.location.href = "/login";
    }
    return Promise.reject(error);
});

export {
    request
};