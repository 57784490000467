import ReactDOM from "react-dom";
import React from "react";
import { createBrowserHistory } from "history";
import { BrowserRouter } from "react-router-dom";
import MainLayout from "./layouts/Main";
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import './App.css';
// optional configuration
const options = {
  // you can also just use 'bottom center'
  position: positions.BOTTOM_CENTER,
  timeout: 5000,
  offset: '30px',
  // you can also just use 'scale'
  transition: transitions.SCALE
}
const hist = createBrowserHistory();

ReactDOM.render(
  <AlertProvider template={AlertTemplate} {...options}>
    <BrowserRouter history={hist}>
      <MainLayout />
    </BrowserRouter>
  </AlertProvider>
  ,
  document.getElementById("root")
);