import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import error from '../assets/error.png'
const Cancel = ({ history }) => {
    return (
        <div className="container text-center">
            <img alt="rh-logo" style={{ margin: '0px auto', display: 'block' }} variant="top" src="https://roashunter.com/wp-content/uploads/2019/12/LOGO_HEAD_PRUEBA-02.png" />
            <img alt="error" style={{marginTop: 50}} src={error} />
            <Typography variant="h4" component="h3">La transacción no ha sido exitosa</Typography>
            <br/>
            <br/>
            <Button onClick={() => history.replace('/pago-de-facturas-roas-hunter')} variant="contained" color="primary"><FontAwesomeIcon icon={faArrowLeft} /> &nbsp;&nbsp;Regresar al inicio</Button>
        </div>
    );
}

export default Cancel;